import React, { useState } from 'react';
import './navbar.scss';
import { IoClose, IoMenu } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
	const [showMenu, setShowMenu] = useState(false);

	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const closeMenuOnMobile = () => {
		if (window.innerWidth <= 1150) {
			setShowMenu(false);
		}
	};

	return (
		<header className="header">
			<nav className="nav container">
				<div
					className={`nav__menu ${showMenu ? 'show-menu' : ''}`}
					id="nav-menu"
				>
					<ul className="nav__list">
						<li className="nav__item">
							<NavLink to="/" className="nav__link" onClick={closeMenuOnMobile}>
								Home
							</NavLink>
						</li>
						<li className="nav__item">
							<NavLink
								to="/story"
								className="nav__link"
								onClick={closeMenuOnMobile}
							>
								Our Story
							</NavLink>
						</li>
						<li className="nav__item">
							<NavLink
								to="/locations"
								className="nav__link"
								onClick={closeMenuOnMobile}
							>
								Locations
							</NavLink>
						</li>
						<li className="nav__item">
							<NavLink
								to="/schedule"
								className="nav__link"
								onClick={closeMenuOnMobile}
							>
								Schedule
							</NavLink>
						</li>
						<li className="nav__item">
							<a
								href="https://www.zola.com/registry/ryanandsam2025"
								target="_blank"
								rel="noopener noreferrer"
								className="nav__link"
								onClick={closeMenuOnMobile}
							>
								Registry
							</a>
						</li>
						<li className="nav__item">
							<NavLink
								to="/faqs"
								className="nav__link"
								onClick={closeMenuOnMobile}
							>
								FAQs
							</NavLink>
						</li>
					</ul>
					<div className="nav__close" id="nav-close" onClick={toggleMenu}>
						<IoClose />
					</div>
				</div>

				<div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
					<IoMenu />
				</div>
			</nav>
		</header>
	);
};

export default Navbar;
